import { render, staticRenderFns } from "./ComplaintPage.vue?vue&type=template&id=cf8b4f28&scoped=true&"
import script from "./ComplaintPage.vue?vue&type=script&lang=js&"
export * from "./ComplaintPage.vue?vue&type=script&lang=js&"
import style0 from "./ComplaintPage.vue?vue&type=style&index=0&id=cf8b4f28&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf8b4f28",
  null
  
)

export default component.exports